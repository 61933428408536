var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"top-menu-item top-menu-item-group",class:{ active: _vm.isActive && !_vm.isOpen }},[_c('b-link',{on:{"click":function($event){return _vm.groupOpen()}}},[_c('div',{class:_vm.isOpen
          ? ("menu-icon icon-" + (_vm.item.icon) + "-secondary")
          : _vm.isActive && !_vm.isOpen
          ? ("menu-icon icon-" + (_vm.item.icon) + "-secondary-active")
          : ("menu-icon icon-" + (_vm.item.icon) + "-secondary")}),_c('span',{staticClass:"top-menu-title"},[_vm._v(_vm._s(_vm.$t(_vm.item.title)))]),_c('div',{class:_vm.isOpen
          ? ("menu-icon-state icon-" + (_vm.item.iconState))
          : _vm.isActive && !_vm.isOpen
          ? ("menu-icon-state icon-" + (_vm.item.iconState) + "-active")
          : ("menu-icon-state icon-" + (_vm.item.iconState))})]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"top-menu-item-child"},_vm._l((_vm.item.children),function(child){return _c(_vm.resolveNavItemComponent(child),{key:child.header || child.title,ref:"groupChild",refInFor:true,tag:"component",attrs:{"item":child},on:{"close":function($event){return _vm.updateGroupOpen(false)}}})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }