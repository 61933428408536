<template>
  <li
    class="top-menu-item top-menu-item-group"
    :class="{ active: isActive && !isOpen }"
  >
    <b-link @click="groupOpen()">
      <div
        :class="
          isOpen
            ? `menu-icon icon-${item.icon}-secondary`
            : isActive && !isOpen
            ? `menu-icon icon-${item.icon}-secondary-active`
            : `menu-icon icon-${item.icon}-secondary`
        "
      />
      <span class="top-menu-title">{{ $t(item.title) }}</span>
      <div
        :class="
          isOpen
            ? `menu-icon-state icon-${item.iconState}`
            : isActive && !isOpen
            ? `menu-icon-state icon-${item.iconState}-active`
            : `menu-icon-state icon-${item.iconState}`
        "
      />
    </b-link>
    <ul v-show="isOpen" class="top-menu-item-child">
      <component
        :is="resolveNavItemComponent(child)"
        v-for="child in item.children"
        :key="child.header || child.title"
        ref="groupChild"
        :item="child"
        @close="updateGroupOpen(false)"
      />
    </ul>
  </li>
</template>

<script>
import { resolveTopNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils";
import TopNavMenuLink from "../top-nav-menu-link/TopNavMenuLink.vue";
import useTopNavMenuGroup from "./useTopNavMenuGroup";
import mixinTopNavMenuGroup from "./mixinTopNavMenuGroup";

export default {
  components: {
    TopNavMenuLink,
  },
  mixins: [mixinTopNavMenuGroup],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isOpen, isActive, updateGroupOpen, updateIsActive } =
      useTopNavMenuGroup(props.item);

    const groupOpen = () => {
      updateGroupOpen(!isOpen.value);

      const itemGroup = document.querySelector(".top-menu-item-group");
      const itemChild = document.querySelector(".top-menu-item-child");
      const width = window.innerWidth - itemGroup.getBoundingClientRect().width;
      if (itemGroup.getBoundingClientRect().right > width) {
        itemChild.style.right = 0;
        itemChild.style.left = "unset";
      } else {
        itemChild.style.left = 0;
        itemChild.style.right = "unset";
      }
    };

    document.addEventListener("click", (e) => {
      if (!e.target.closest(".top-menu-item-group")) {
        updateGroupOpen(false);
      }
    });

    return {
      resolveNavItemComponent,
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,
      groupOpen,
    };
  },
};
</script>
