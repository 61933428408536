
export default [
//    {
//        title: 'Главная',
//        route: 'admin-dashboard',
//        icon: 'home',
//        group: "admin"
//    },
    {
        title: 'Пользователи',
        route: 'admin-users-list',
        icon: 'users',
        group: "admin"
    },
    {
        title: 'Предложения',
        route: 'admin-offers-list',
        icon: 'documents',
        group: "admin"
    },
    {
        title: 'Статистика',
        route: "admin-statistics-list",
        icon: 'documents',
        group: "admin"
    }, 
    {
        title: 'Заявки предложений',
        route: "admin-orders-list",
        icon: 'documents',
        group: "admin"
    },
    {
        title: 'Заявки доступа',
        route: 'admin-applications-opening-offers-list',
        icon: 'documents',
        group: "admin"
    },
    {
        title: 'Выплаты',
        route: 'admin-payouts-list',
        icon: 'documents',
        group: "admin"
    },
    {
        title: "Настройки",
        icon: 'settings',
        route: 'admin-settings',
        group: "admin",
        children: [
            {  route: 'admin-system-settings', icon: 'sub', title: "Основное", group: "admin" },
            {  route: 'admin-group-perms', icon: 'sub', title: "Права групп пользователей", group: "admin" },
            
        ]
    }
]