<template>
  <top-nav-menu-items :items="menuItemsWithRouter" class="top-menu" />
</template>

<script>
import navMenuItemsAccount from "@/navigation/vertical/account";
import navMenuItems from "@/navigation/vertical";
import TopNavMenuItems from "./components/top-nav-menu-items/TopNavMenuItems.vue";

export default {
  data() {
    return {
      navMenuItemsAccount,
      navMenuItems,
    };
  },
  components: {
    TopNavMenuItems,
  },
  computed: {
    menuItemsWithRouter() {
      if (this.$route.name.includes("user-account"))
        return this.navMenuItemsAccount;
      else {
        return this.navMenuItems;
      }
    },
  },
};
</script>

<style lang="scss">
.top-menu {
  list-style: none;
  padding: 0 0 20px 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  @media screen and (max-width: 1024px) {
    padding: 20px 0;
  }

  .top-menu-item {
    display: flex;
    align-items: center;

    padding: 6px 14px;
    background: var(--white);
    border-radius: 40px;

    a {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .top-menu-title {
      color: var(--text-color);
    }

    &.active {
      background: var(--primary);

      .top-menu-title {
        color: var(--white);
      }
    }
  }

  .top-menu-item-group {
    position: relative;
    .top-menu-item-child {
      position: absolute;
      top: 40px;
      left: 0;
      z-index: 2;
      padding: 10px 15px;
      background: var(--white);
      border-radius: 25px;

      .top-menu-item {
        width: max-content;
      }
    }
  }
}

.dark-layout .top-menu {
  .top-menu-item {
    background: var(--message-in-bg-color);
    
    &.active {
      background: var(--primary);

      .top-menu-title {
        color: var(--white);
      }
    }
  }

  .top-menu-item-group {
    .top-menu-item-child {
      background: var(--message-in-bg-color);
    }
  }
}
</style>
