<template>
  <li
    class="top-menu-item"
    :class="{
      active: isActive,
    }"
  >
    <b-link v-bind="linkProps" @click="$emit('close')">
      <div
        v-if="item.icon"
        :class="
          isActive
            ? `menu-icon icon-${item.icon}-secondary-active`
            : `menu-icon icon-${item.icon}-secondary`
        "
      />
      <span class="top-menu-title">{{ $t(item.title) }}</span>
    </b-link>
  </li>
</template>

<script>
import useTopNavMenuLink from "./useTopNavMenuLink";
import mixinTopNavMenuLink from "./mixinTopNavMenuLink";

export default {
  mixins: [mixinTopNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useTopNavMenuLink(
      props.item
    );

    return {
      isActive,
      linkProps,
      updateIsActive,
    };
  },
};
</script>
