export default [
  {
    title: 'Личная информация',
    route: 'user-account-info',
    icon: 'home',
    visibility: "public",
    group: "user",
  },
  {
    title: 'Программа лояльности',
    route: 'user-account-loyalty',
    icon: 'home',
    visibility: "public",
    group: "user",
  },
  {
    title: 'Подписки',
    route: 'user-account-subscriptions',
    icon: 'home',
    visibility: "public",
    group: "user",
  },
  {
    title: 'Сменить пароль',
    route: 'user-account-password',
    icon: 'home',
    visibility: "public",
    group: "user",
  },

]