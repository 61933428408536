
 import router from '@/router'


 import User from "@/modules/user/"
 import UserNav from "./user"
 import AdminNav from "./administrator"

 let navigation = [];

if( router.app.$route.meta ) {
    switch( router.app.$route.meta.group ) {
        case 'administrator': navigation = AdminNav; break;
        default: navigation = UserNav; break;
    }

    if (router.app.$route.meta.group == undefined && window.location.href.includes('/admin')) {
        navigation = AdminNav;
    }
}
 
 export default navigation;