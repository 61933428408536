<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveTopNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils";
import { provide, ref } from "@vue/composition-api";
import TopNavMenuLink from "../top-nav-menu-link/TopNavMenuLink.vue";
import TopNavMenuGroup from "../top-nav-menu-group/TopNavMenuGroup.vue";

export default {
  components: {
    TopNavMenuLink,
    TopNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    provide("openTopGroups", ref([]));
    return {
      resolveNavItemComponent,
    };
  },
};
</script>
