
export default [
    {
        title: 'navigation.home',
        route: 'user-dashboard',
        icon: 'home',
        visibility: "public",
        group: "user",
    },
    {
        title: 'navigation.special',
        route: 'user-offers-special',
        query: { category: 'special' },
        icon: 'home',
        group: "user"
    },
    {
        title: 'navigation.personal',
        route: 'user-offers-personal',
        icon: 'home',
        group: "user"
    },
    {
        title: 'navigation.premium',
        route: 'user-offers-premium',
        query: { category: 'premium' },
        icon: 'home',
        group: "user"
    },
    {
        title: 'navigation.banks',
        icon: 'home',
        visibility: "public",
        iconState: "chevron-down",
        children: [
            { title: 'navigation.account-opening', group: "user", route: "user-offers-account-opening",  query: { category: 'account-opening' },},
            { title: 'navigation.debit-card', group: "user", route: "user-offers-debit-card", query: { category: 'debit-card' },},
            { title: 'navigation.express-credit', group: "user", route: "user-offers-express-credit", query: { category: 'express-credit' },},
            { title: 'navigation.credit', group: "user", route: "user-offers-credit", query: { category: 'credit' },},
            { title: 'navigation.car-credit', group: "user", route: "user-offers-car-credit", query: { category: 'car-credit' },}
        ]
    },
    {
        title: 'navigation.betting',
        route: 'user-offers-betting',
        query: { category: 'betting' },
        icon: 'home',
        group: "user"
    },
    {
        title: 'navigation.insurance',
        icon: 'home',
        visibility: "public",
        iconState: "chevron-down",
        children: [
            { title: 'navigation.osago', group: "user", route: "user-offers-osago",  query: { category: 'osago' },},
            { title: 'navigation.kasko', group: "user", route: "user-offers-kasko", query: { category: 'kasko' },},
        ]
    },
    {
        title: 'navigation.hr',
        route: 'user-offers-hr',
        query: { category: 'hr' },
        icon: 'home',
        group: "user"
    },
    {
        title: 'navigation.investments',
        route: 'user-offers-investments',
        query: { category: 'investments' },
        icon: 'home',
        group: "user"
    },
    {
        title: 'navigation.tourism',
        route: 'user-offers-tourism',
        query: { category: 'tourism' },
        icon: 'home',
        group: "user"
    },
    {
        title: 'navigation.vpn',
        route: 'user-offers-vpn',
        query: { category: 'vpn' },
        icon: 'home',
        group: "user"
    }
]