import { ref, watch, inject } from '@vue/composition-api'
import { isNavGroupActive } from '@core/layouts/utils'

export default function useTopNavMenuGroup(item) {

  const openGroups = inject('openTopGroups')

  watch(openGroups, currentOpenGroups => {
    const clickedGroup = currentOpenGroups[currentOpenGroups.length - 1]
    if (clickedGroup !== item.title) {
      if (!doesHaveChild(clickedGroup)) isOpen.value = false
    }
  })

  const isOpen = ref(false)
  watch(isOpen, val => {
    if (val) openGroups.value.push(item.title)
  })

  const updateGroupOpen = val => {
    isOpen.value = val
  }

  const isActive = ref(false)
  watch(isActive, val => {
    if (val) {
      isOpen.value = val
    }
  })

  const updateIsActive = () => {
    isActive.value = isNavGroupActive(item.children)
  }

  const doesHaveChild = title => item.children.some(child => child.title === title)

  return {
    isOpen,
    isActive,
    updateGroupOpen,
    openGroups,
    updateIsActive,
  }
}
