<template>
  <div
    class="app-content content"
    :class="[
      { 'show-overlay': $store.state.app.shallShowOverlay },
      $route.meta.contentClass,
    ]"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />

    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >
      <!-- <slot name="breadcrumb">
          <app-breadcrumb />
        </slot> -->

      <showAt
        breakpoint="mediumAndBelow"
        v-if="
          $route.name.includes('user-dashboard') ||
          $route.name.includes('user-offers') ||
          $route.name.includes('user-account')
        "
      >
        <top-nav-menu />
      </showAt>

      <showAt breakpoint="large">
        <div
          v-if="canGoBack && (User.isAuth() || $route.meta.layout === 'custom')"
          class="d-flex"
          style="margin-bottom: 16px; cursor: pointer"
        >
          <div @click="$router.back()" class="d-flex q-block">
            <div class="q-icon icon-chevron-left"></div>
            <span
              class="text-muted mt-auto mb-auto"
              style="margin-left: 12px"
              >{{ $t("navigation.back") }}</span
            >
          </div>
        </div>
      </showAt>

      <showAt breakpoint="mediumAndBelow">
        <div
          v-if="canGoBack && (User.isAuth() || $route.meta.layout === 'custom')"
          class="d-flex"
          style="margin: 16px 0; cursor: pointer"
        >
          <div @click="$router.back()" class="d-flex q-block">
            <div class="q-icon icon-chevron-left"></div>
            <span
              class="text-muted mt-auto mb-auto"
              style="margin-left: 12px"
              >{{ $t("navigation.back") }}</span
            >
          </div>
        </div>
      </showAt>

      <showAt breakpoint="large">
        <h2
          v-if="$route.meta.layout === 'custom'"
          style="font-size: 48px; line-height: 48px; margin-bottom: 32px"
        >
          {{ $t($route.meta.pageTitle) }}
        </h2>
      </showAt>

      <showAt breakpoint="mediumAndBelow">
        <h2
          v-if="$route.meta.layout === 'custom'"
          style="
            font-size: 22px;
            line-height: 22px;
            margin-bottom: 16px;
            margin-left: 16px;
          "
        >
          {{ $t($route.meta.pageTitle) }}
        </h2>
      </showAt>

      <div
        class="content-body"
        :style="{
          paddingBottom: cart.length > 0 ? '120px' : '88px',
        }"
      >
        <transition :name="routerTransition" mode="out-in">
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import AppBreadcrumb from "@core/layouts/components/AppBreadcrumb.vue";
import useAppConfig from "@core/app-config/useAppConfig";
import User from "@/modules/user";

import TopNavMenu from "@/@core/layouts/layout-vertical/components/top-nav-menu/TopNavMenu";

export default {
  data: () => {
    return {
      User,
      // canGoBack: true
    };
  },
  watch: {
    // "$route.path" : function() {
    //   this.canGoBack = ( window.history.length > 3 );
    // }
  },
  computed: {
    cart() {
      return [];
    },
    canGoBack() {
      return window.history.length > 3 && this.$route.name !== "user-dashboard" && this.$route.name !== "welcome-page";
    },
  },
  components: {
    AppBreadcrumb,
    TopNavMenu,
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig();

    return {
      routerTransition,
      contentWidth,
    };
  },
};
</script>

<style>
</style>
