var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-content content",class:[
    { 'show-overlay': _vm.$store.state.app.shallShowOverlay },
    _vm.$route.meta.contentClass ]},[_c('div',{staticClass:"content-overlay"}),_c('div',{staticClass:"header-navbar-shadow"}),_c('div',{staticClass:"content-wrapper",class:_vm.contentWidth === 'boxed' ? 'container p-0' : null},[(
        _vm.$route.name.includes('user-dashboard') ||
        _vm.$route.name.includes('user-offers') ||
        _vm.$route.name.includes('user-account')
      )?_c('showAt',{attrs:{"breakpoint":"mediumAndBelow"}},[_c('top-nav-menu')],1):_vm._e(),_c('showAt',{attrs:{"breakpoint":"large"}},[(_vm.canGoBack && (_vm.User.isAuth() || _vm.$route.meta.layout === 'custom'))?_c('div',{staticClass:"d-flex",staticStyle:{"margin-bottom":"16px","cursor":"pointer"}},[_c('div',{staticClass:"d-flex q-block",on:{"click":function($event){return _vm.$router.back()}}},[_c('div',{staticClass:"q-icon icon-chevron-left"}),_c('span',{staticClass:"text-muted mt-auto mb-auto",staticStyle:{"margin-left":"12px"}},[_vm._v(_vm._s(_vm.$t("navigation.back")))])])]):_vm._e()]),_c('showAt',{attrs:{"breakpoint":"mediumAndBelow"}},[(_vm.canGoBack && (_vm.User.isAuth() || _vm.$route.meta.layout === 'custom'))?_c('div',{staticClass:"d-flex",staticStyle:{"margin":"16px 0","cursor":"pointer"}},[_c('div',{staticClass:"d-flex q-block",on:{"click":function($event){return _vm.$router.back()}}},[_c('div',{staticClass:"q-icon icon-chevron-left"}),_c('span',{staticClass:"text-muted mt-auto mb-auto",staticStyle:{"margin-left":"12px"}},[_vm._v(_vm._s(_vm.$t("navigation.back")))])])]):_vm._e()]),_c('showAt',{attrs:{"breakpoint":"large"}},[(_vm.$route.meta.layout === 'custom')?_c('h2',{staticStyle:{"font-size":"48px","line-height":"48px","margin-bottom":"32px"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.pageTitle))+" ")]):_vm._e()]),_c('showAt',{attrs:{"breakpoint":"mediumAndBelow"}},[(_vm.$route.meta.layout === 'custom')?_c('h2',{staticStyle:{"font-size":"22px","line-height":"22px","margin-bottom":"16px","margin-left":"16px"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.pageTitle))+" ")]):_vm._e()]),_c('div',{staticClass:"content-body",style:({
        paddingBottom: _vm.cart.length > 0 ? '120px' : '88px',
      })},[_c('transition',{attrs:{"name":_vm.routerTransition,"mode":"out-in"}},[_vm._t("default")],2)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }